import {
  Heading,
  Text,
  VStack,
  Button,
  UnorderedList,
  ListItem,
  Icon,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  useBreakpointValue,
  Flex,
} from "@chakra-ui/react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Link as ChakraLink } from "@chakra-ui/react";
import { FaShoppingCart } from "react-icons/fa";
import React, { useEffect, useState } from "react";
import { MdChevronRight } from "react-icons/md";
import { useQuery } from "react-query";
import ContactForm from "../../course-details/contactForm";
import { useCart } from "../../../cart/addToCart";
import axios from "axios";

function NVQLevel7StrategicHealthAndSafety() {
  const points = [
    "Remotely – ONLINE PORTFOLIO",
    "FAST TRACK COMPLETION 8-12 weeks",
    "Instalment payment plans available",
    "Leading to BLACK CSCS Card",
    "Access to MCIOB",
  ];
  const { addToCart } = useCart();

  const { isPending, error, data } = useQuery(
    ["StrategicHealthandSafety"],
    () => {
      return axios.get(`product/filter`, {
        params: {
          filter: "detailsPage",
          equal: "/Strategic-Health-and-Safety",
        },
      });
    }
  );

  const navigate = useNavigate();
  const AddToCart = (data) => {
    const { _id, name, price } = data;
    addToCart(
      { _id, name, price, vat: data.productCategory.vat, quantity: 1 },
      0
    );
    navigate("/checkout");
  };
  return (
    <Flex
      direction={useBreakpointValue({ base: "column", md: "row" })}
      bg="white"
      p={useBreakpointValue({ base: 2, md: 4 })}
      justify="space-between"
      px={useBreakpointValue({ base: 6, md: "4rem" })}
      maxW="150ch"
      gap={useBreakpointValue({ base: 2, md: 5 })}
      mx="auto"
      position="relative"
      minHeight="100vh"
    >
      <VStack
        spacing={4}
        align="stretch"
        w={useBreakpointValue({ base: "100%", md: "55%" })}
        px={useBreakpointValue({ base: 0, md: 10 })}
        my={useBreakpointValue({ base: 4, md: "3rem" })}
      >
        <Breadcrumb
          mb={5}
          fontSize="xs"
          fontWeight={500}
          spacing="4px"
          separator={<MdChevronRight color="gray.500" />}
        >
          <BreadcrumbItem>
            <BreadcrumbLink href="/">Home</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink href="/Strategic-Health-and-Safety">
              Strategic Health & Safety
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Heading as="h1" size="lg" color="#941C1C" mb={3}>
          NVQ Level 7 Diploma in Strategic Health & Safety
        </Heading>
        <UnorderedList
          style={{
            listStyle: "circle",
            color: "black",
            marginBottom: 15,
          }}
        >
          {points.map((point, index) => (
            <ListItem key={index}>
              <Text>{point}</Text>
            </ListItem>
          ))}
        </UnorderedList>
        <Text fontSize="xl" fontWeight="600">
          £{data?.data.result[0].price} + VAT
        </Text>
        <Flex
          direction={useBreakpointValue({ base: "column", md: "row" })}
          justify={useBreakpointValue({ base: "center", md: "space-between" })}
          align="center"
          gap={useBreakpointValue({ base: 2, md: 5 })}
        >
          <Button
            onClick={() => {
              AddToCart(data?.data.result[0]);
            }}
            w={useBreakpointValue({ base: "100%", md: "50%" })}
            borderRadius="2rem"
            color="white"
            bg="#941C1C"
            _hover={{ filter: "brightness(105%)" }}
            transition="all ease-in-out 200ms"
          >
            <Icon as={FaShoppingCart} mr={3} />
            Book Now
          </Button>
        </Flex>
        <VStack mt={6} textAlign="left" justify="start" align="start">
          <Text>
            The NVQ Level 7 Diploma in Strategic Health & Safety Management and
            Leadership qualification is aimed at candidates who are responsible
            for developing and applying health and safety procedures day-to-day
            in any organisation. They are likely to be managers looking to
            improve their knowledge and skills.
          </Text>
          <Text fontWeight="bold">Entry Requirements</Text>
          <Text>
            You must be able to demonstrate that you can meet the skills,
            knowledge and experience requirements of the NVQ before enrolment.
          </Text>
          <Text fontWeight="bold">How long will my NVQ take to complete?</Text>
          <Text>
            An assessment plan will be agreed during your Initial
            assessment/Induction and a planned end date agreed. However, we
            would expect your qualification to be complete with 6 weeks
            depending on the learners time availability.
          </Text>
          <Heading size="md" mb={2}>
            Completion of Units
          </Heading>
          <Text>Learners must complete all 5 mandatory units:</Text>
          <Heading fontSize="lg"> Mandatory units</Heading>
          Credit value required: minimum 59.
          <UnorderedList>
            <ListItem>Risk-based safety systems management </ListItem>

            <ListItem>
              Safety culture, sustainability and the global effect on
              performance
            </ListItem>

            <ListItem>Digital technologies and incident investigation</ListItem>

            <ListItem>Development as a strategic manager</ListItem>
            <ListItem>Establish business risk management processes</ListItem>
          </UnorderedList>
          <Text fontWeight="bold">Industry recognition & benefits</Text>
          <Text>
            The NVQ Level 7 Diploma in Strategic Health & Safety Management and
            Leadership qualifies the holder to apply for the Construction Skills
            Certification Scheme (CSCS)
            <ChakraLink
              as={RouterLink}
              to="/detail-page-6"
              color="blue.500"
              isExternal
            >
              Black CSCS Card
            </ChakraLink>
          </Text>
          <Text>
            A The NVQ Level 7 Diploma in Strategic Health & Safety Management
            and Leadership allows you access to becoming Chartered with MCIOB
            and Chartered Member with IOSH{" "}
          </Text>
        </VStack>
      </VStack>
      <ContactForm />
    </Flex>
  );
}

export default NVQLevel7StrategicHealthAndSafety;
