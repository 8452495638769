import { useToast } from "@chakra-ui/react";
// CartContext.js
import React, { createContext, useState, useContext } from "react";

const CartContext = createContext();

export const useCart = () => {
  return useContext(CartContext);
};

export const CartProvider = ({ children }) => {
  const [cart, setCart] = useState([]);
  const toast = useToast();
  const addToCart = (item) => {
    const existingCart = JSON.parse(localStorage.getItem("cart")) || [];
    const existingItem = existingCart.find(
      (cartItem) => cartItem._id === item._id
    );

    if (existingItem) {
      existingItem.quantity += item.quantity;
    } else {
      existingCart.push(item);
    }

    localStorage.setItem("cart", JSON.stringify(existingCart));

    // Display toast notification
    toast({
      title: "Item added to cart!",
      status: "success",
      duration: 3000,
      isClosable: true,
      position: "bottom",
      align: "center",
      variant: "subtle",
    });
    setCart([...existingCart]);
  };

  const quantityChange = (index, value) => {
    const newCart = [...cart];
    newCart[index].quantity = Math.max(1, value);
    setCart(newCart);
    localStorage.setItem("cart", JSON.stringify(newCart));
  };
  const removeFromCart = (index) => {
    const newCart = [...cart];
    newCart.splice(index, 1);
    setCart(newCart);
    localStorage.setItem("cart", JSON.stringify(newCart));
  };

  const cartCount = cart.reduce((total, item) => total + item.quantity, 0);

  return (
    <CartContext.Provider
      value={{ cart, addToCart, removeFromCart, cartCount, quantityChange }}
    >
      {children}
    </CartContext.Provider>
  );
};

// Custom hook for adding items to the cart and displaying toast notifications
