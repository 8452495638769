import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ChakraProvider } from "@chakra-ui/react";
import customTheme from "./customTheme";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom/dist";
import Home from "./pages/homePage";
import CITBPage from "./pages/citbPage";
import ContactPage from "./pages/contactPage";
import AboutUsPage from "./pages/aboutusPage";
import CSCSCardsPage from "./pages/cscsPage";
import PlantOperationsPage from "./pages/plantOperationsPage";
import LevelOnePage from "./pages/nvqPages/levelOnePage";
import LevelTwoPage from "./pages/nvqPages/levelTwoPage";
import LevelThreePage from "./pages/nvqPages/levelThreePage";
import LevelFourPage from "./pages/nvqPages/levelFourPage";
import LevelSixPage from "./pages/nvqPages/levelSixPage";
import LevelSevenPage from "./pages/nvqPages/levelSevenPage";
import NvqHealthSafetyPage from "./pages/nvqPages/nvqHealth&SafetyPage";
import PrivacyPolicyPage from "./pages/privacyPolicyPage";
import TermsAndConditionsPage from "./pages/termsAndConditionsPage";
import CookiesPolicyPage from "./pages/cookiesPolicyPage";
import NVQLevel3Page from "./pages/nvqPages/nvqLevel3DiplomaPage";
import NVQLevel4Page from "./pages/nvqPages/nvqLevel4OccupationalPage";
import NVQLevel6DiplomaContractingPage from "./pages/nvqPages/nvqLevel6DiplomaContractingPage";
import NVQLevel6DiplomaConstructionPage from "./pages/nvqPages/nvqLevel6DiplomaConstructionPage";
import NVQLevel7Page from "./pages/nvqPages/nvqLevel7ConstructionPage";
import CitbHealthAndSafetyPage from "./pages/citbHealthAndSafetyPage";
import CartPage from "./pages/cart/cartPage";
import CheckOutPage from "./pages/cart/checkOutPage";
import CourseDetail1Page from "./pages/nvqPages/course-details/nvqTwoDetailPage.jsx/nvqLevelTwoCourseDetail1Page";
import CourseDetail2Page from "./pages/nvqPages/course-details/nvqTwoDetailPage.jsx/nvqLevelTwoCourseDetail2Page";
import CourseDetail3Page from "./pages/nvqPages/course-details/nvqTwoDetailPage.jsx/nvqLevelTwoCourseDetail3Page";
import CourseDetail4Page from "./pages/nvqPages/course-details/nvqTwoDetailPage.jsx/nvqLevelTwoCourseDetail4Page";
import CourseDetail5Page from "./pages/nvqPages/course-details/nvqTwoDetailPage.jsx/nvqLevelTwoCourseDetail5Page";
import CourseDetail6Page from "./pages/nvqPages/course-details/nvqTwoDetailPage.jsx/nvqLevelTwoCourseDetail6Page";
import CourseDetail7Page from "./pages/nvqPages/course-details/nvqTwoDetailPage.jsx/nvqLevelTwoCourseDetail7Page";
import CourseDetail8Page from "./pages/nvqPages/course-details/nvqTwoDetailPage.jsx/nvqLevelTwoCourseDetail8Page";
import CourseDetail9Page from "./pages/nvqPages/course-details/nvqTwoDetailPage.jsx/nvqLevelTwoCourseDetail9Page";
import CourseDetail10Page from "./pages/nvqPages/course-details/nvqTwoDetailPage.jsx/nvqLevelTwoCourseDetail10Page";
import CourseDetail11Page from "./pages/nvqPages/course-details/nvqTwoDetailPage.jsx/nvqLevelTwoCourseDetail11Page";
import CourseDetail111Page from "./pages/nvqPages/course-details/nvqTwoDetailPage.jsx/nvqLevelTwoCourseDetail11_1Page.jsx";
import CourseDetail112Page from "./pages/nvqPages/course-details/nvqTwoDetailPage.jsx/nvqLevelTwoCourseDetail11_2Page.jsx";
import CourseDetail113Page from "./pages/nvqPages/course-details/nvqTwoDetailPage.jsx/nvqLevelTwoCourseDetail11_3Page.jsx";
import CourseDetail114Page from "./pages/nvqPages/course-details/nvqTwoDetailPage.jsx/nvqLevelTwoCourseDetail11_4Page.jsx";
import CourseDetail115Page from "./pages/nvqPages/course-details/nvqTwoDetailPage.jsx/nvqLevelTwoCourseDetail11_5Page.jsx";
import CourseDetail116Page from "./pages/nvqPages/course-details/nvqTwoDetailPage.jsx/nvqLevelTwoCourseDetail11_6Page.jsx";
import CourseDetail12Page from "./pages/nvqPages/course-details/nvqTwoDetailPage.jsx/nvqLevelTwoCourseDetail12Page";
import CourseDetail13Page from "./pages/nvqPages/course-details/nvqTwoDetailPage.jsx/nvqLevelTwoCourseDetail13Page";
import CourseDetail14Page from "./pages/nvqPages/course-details/nvqTwoDetailPage.jsx/nvqLevelTwoCourseDetail14Page";
import CourseDetail15Page from "./pages/nvqPages/course-details/nvqTwoDetailPage.jsx/nvqLevelTwoCourseDetail15Page";
import CourseDetail151Page from "./pages/nvqPages/course-details/nvqTwoDetailPage.jsx/nvqLevelTwoCourseDetail15_1Page";
import CourseDetail16Page from "./pages/nvqPages/course-details/nvqTwoDetailPage.jsx/nvqLevelTwoCourseDetail16Page";
import CourseDetail161Page from "./pages/nvqPages/course-details/nvqTwoDetailPage.jsx/nvqLevelTwoCourseDetail16_1Page";
import CourseDetail162Page from "./pages/nvqPages/course-details/nvqTwoDetailPage.jsx/nvqLevelTwoCourseDetail16_2Page";
import CourseDetail163Page from "./pages/nvqPages/course-details/nvqTwoDetailPage.jsx/nvqLevelTwoCourseDetail16_3Page";
import CourseDetail164Page from "./pages/nvqPages/course-details/nvqTwoDetailPage.jsx/nvqLevelTwoCourseDetail16_4Page";
import CourseDetail165Page from "./pages/nvqPages/course-details/nvqTwoDetailPage.jsx/nvqLevelTwoCourseDetail16_5Page";
import CourseDetail17Page from "./pages/nvqPages/course-details/nvqTwoDetailPage.jsx/nvqLevelTwoCourseDetail17Page";
import CourseDetail18Page from "./pages/nvqPages/course-details/nvqTwoDetailPage.jsx/nvqLevelTwoCourseDetail18Page";
import CourseDetail19Page from "./pages/nvqPages/course-details/nvqTwoDetailPage.jsx/nvqLevelTwoCourseDetail19Page";
import CourseDetail20Page from "./pages/nvqPages/course-details/nvqTwoDetailPage.jsx/nvqLevelTwoCourseDetail20Page";
import CourseDetail21Page from "./pages/nvqPages/course-details/nvqTwoDetailPage.jsx/nvqLevelTwoCourseDetail21Page";
import CourseDetail22Page from "./pages/nvqPages/course-details/nvqTwoDetailPage.jsx/nvqLevelTwoCourseDetail22Page";
import CourseDetailLevelThree1Page from "./pages/nvqPages/course-details/nvqLevelThreeDetailPage.jsx/LevelThreeDetailPageOne";
import CourseDetailLevelThree1OnePage from "./pages/nvqPages/course-details/nvqLevelThreeDetailPage.jsx/LevelThreeDetailPageOne1.jsx";
import CourseDetailLevelThree1TwoPage from "./pages/nvqPages/course-details/nvqLevelThreeDetailPage.jsx/LevelThreeDetailPageOne2.jsx";
import CourseDetailLevelThree1ThreePage from "./pages/nvqPages/course-details/nvqLevelThreeDetailPage.jsx/LevelThreeDetailPageOne3.jsx";
import CourseDetailLevelThree1FourPage from "./pages/nvqPages/course-details/nvqLevelThreeDetailPage.jsx/LevelThreeDetailPageOne4.jsx";
import CourseDetailLevelThree1FivePage from "./pages/nvqPages/course-details/nvqLevelThreeDetailPage.jsx/LevelThreeDetailPageOne5.jsx";
import CourseDetailLevelThree2Page from "./pages/nvqPages/course-details/nvqLevelThreeDetailPage.jsx/LevelThreeDetailPageTwo";
import CourseDetailLevelThree3Page from "./pages/nvqPages/course-details/nvqLevelThreeDetailPage.jsx/LevelThreeDetailPageThree";
import CourseDetailLevelThree4Page from "./pages/nvqPages/course-details/nvqLevelThreeDetailPage.jsx/LevelThreeDetailPageFour";
import CourseDetailLevelThree5Page from "./pages/nvqPages/course-details/nvqLevelThreeDetailPage.jsx/LevelThreeDetailPageFive";
import CourseDetailLevelFour1Page from "./pages/nvqPages/course-details/nvqFourDetailPage.jsx/LevelFourDetailPageOne";
import CourseDetailLevelSix1Page from "./pages/nvqPages/course-details/nvqSixDetailPage.jsx/LevelSixDetailPageOne";
import CourseDetailLevelSix1OnePage from "./pages/nvqPages/course-details/nvqSixDetailPage.jsx/LevelSixDetailPageOne1";
import CourseDetailLevelSix1TwoPage from "./pages/nvqPages/course-details/nvqSixDetailPage.jsx/LevelSixDetailPageOne2";
import CourseDetailLevelSix1ThreePage from "./pages/nvqPages/course-details/nvqSixDetailPage.jsx/LevelSixDetailPageOne3";
import CourseDetailLevelSix1FourPage from "./pages/nvqPages/course-details/nvqSixDetailPage.jsx/LevelSixDetailPageOne4";
import CourseDetailLevelSix2Page from "./pages/nvqPages/course-details/nvqSixDetailPage.jsx/LevelSixDetailPageTwo";
import CourseDetailLevelSix2OnePage from "./pages/nvqPages/course-details/nvqSixDetailPage.jsx/LevelSixDetailPageTwo1";
import CourseDetailLevelSix2TwoPage from "./pages/nvqPages/course-details/nvqSixDetailPage.jsx/LevelSixDetailPageTwo2";
import CourseDetailLevelSix2ThreePage from "./pages/nvqPages/course-details/nvqSixDetailPage.jsx/LevelSixDetailPageTwo3";
import CourseDetailLevelSix2FourPage from "./pages/nvqPages/course-details/nvqSixDetailPage.jsx/LevelSixDetailPageTwo4";
import NvqHealthAndSafetyDetailPage1 from "./pages/nvqPages/course-details/nvqHealthAndSafetyPage/nvqHealthAndSafetyDetailPage1.jsx";
import NvqHealthAndSafetyDetailPage2 from "./pages/nvqPages/course-details/nvqHealthAndSafetyPage/nvqHealthAndSafetyDetailPage2.jsx";
import DetailPage1 from "./pages/CscsCardsPage/DetailPage1";
import DetailPage2 from "./pages/CscsCardsPage/DetailPage2";
import DetailPage3 from "./pages/CscsCardsPage/DetailPage3";
import DetailPage4 from "./pages/CscsCardsPage/DetailPage4";
import DetailPage5 from "./pages/CscsCardsPage/DetailPage5";
import DetailPage6 from "./pages/CscsCardsPage/DetailPage6";
import DetailPage7 from "./pages/CscsCardsPage/DetailPage7";
import DetailPage8 from "./pages/CscsCardsPage/DetailPage8";
import DetailPage9 from "./pages/CscsCardsPage/DetailPage9";
import DetailPage10 from "./pages/CscsCardsPage/DetailPage10";
import DetailPage11 from "./pages/CscsCardsPage/DetailPage11";
import DetailPage12 from "./pages/CscsCardsPage/DetailPage12";
import DetailPage13 from "./pages/CscsCardsPage/DetailPage13";
import { QueryClient, QueryClientProvider } from "react-query";
import PaymentPage from "./pages/cart/paymentPage";
import PaymentSuccess from "./pages/paymentSuccess";
import PaymentFailed from "./pages/paymentFailed";
import NotFoundPage from "./components/NotFound";
import CourseDetailLevelFour1OnePage from "./pages/nvqPages/course-details/nvqFourDetailPage.jsx/LevelFourDetailPageOne1.jsx";
import CourseDetailLevelFour1TwoPage from "./pages/nvqPages/course-details/nvqFourDetailPage.jsx/LevelFourDetailPageOne2.jsx";
import { CartProvider } from "./pages/cart/addToCart.jsx";
import SsstsOnlineTwoDays from "./pages/CitbCoursesDetailPage/SsstsPage.jsx";
import SsstsOnlineRefresherOneDay from "./pages/CitbCoursesDetailPage/SsstarefresherPage.jsx";
import SmstsFiveDays from "./pages/CitbCoursesDetailPage/Smsts5DayPage.jsx";
import SmstsRefresherOnlineTwoDays from "./pages/CitbCoursesDetailPage/Smsts2DayPage.jsx";
import CourseDetailLevelFour1ThreePage from "./pages/nvqPages/course-details/nvqFourDetailPage.jsx/LevelFourDetailPageOne3.jsx";
import CourseDetailLevelFour1FourPage from "./pages/nvqPages/course-details/nvqFourDetailPage.jsx/LevelFourDetailPageOne4.jsx";
import CourseDetailLevelFour1FivePage from "./pages/nvqPages/course-details/nvqFourDetailPage.jsx/LevelFourDetailPageOne5.jsx";
import NVQLevel7StrategicHealthAndSafety from "./pages/nvqPages/course-details/nvqHealthAndSafetyPage/nvqLevel7StrategicHealthAndSafetyPage.jsx";
import CourseDetailLevelSix2FivePage from "./pages/nvqPages/course-details/nvqSixDetailPage.jsx/LevelSixDetailPageTwo5.jsx";
import ThankYouPage from "./pages/thankYouPage.jsx";
import CourseDetailLevelThree6Page from "./pages/nvqPages/course-details/nvqLevelThreeDetailPage.jsx/LevelThreeDetailPageSix.jsx";
import CampaignForm from "./pages/campaignPage.jsx";

// import SideCart from "./components/sideCart";
const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      <Route path="*" element={<NotFoundPage />} />
      <Route path="/" element={<Home />} index={true} />
      <Route path="/cart" element={<CartPage />} />
      <Route path="/checkout" element={<CheckOutPage />} />
      <Route path="/payment" element={<PaymentPage />} />
      <Route path="/level-one-page" element={<LevelOnePage />} />
      <Route path="/level-two-page" element={<LevelTwoPage />} />
      <Route
        path="/nvq-level-two-industrial-painter"
        element={<CourseDetail1Page />}
      />
      <Route
        path="/nvq-level-two-heritage-painter"
        element={<CourseDetail2Page />}
      />
      <Route path="/nvq-level-two-painter" element={<CourseDetail3Page />} />
      <Route
        path="/nvq-level-two-ceiling-fixing"
        element={<CourseDetail4Page />}
      />
      <Route
        path="/nvq-level-two-dry-lining-finishing"
        element={<CourseDetail5Page />}
      />
      <Route
        path="/nvq-level-two-lining-border"
        element={<CourseDetail6Page />}
      />
      <Route
        path="/nvq-level-two-cavity-barrier-installation"
        element={<CourseDetail7Page />}
      />
      <Route
        path="/nvq-level-two-lifting-operations"
        element={<CourseDetail8Page />}
      />
      <Route
        path="/nvq-level-two-site-carpentry"
        element={<CourseDetail9Page />}
      />
      <Route
        path="/nvq-level-two-plant-operation"
        element={<CourseDetail10Page />}
      />
      <Route
        path="/nvq-level-two-movement-guide"
        element={<CourseDetail11Page />}
      />
      <Route
        path="/nvq-level-two-cranes-and-specialist-lifting"
        element={<CourseDetail111Page />}
      />
      <Route
        path="/certificate-plant-operation-construction"
        element={<CourseDetail112Page />}
      />
      <Route
        path="/certificate-plant-operation-constructionOne"
        element={<CourseDetail113Page />}
      />
      <Route
        path="/certificate-plant-operation-constructionTwo"
        element={<CourseDetail114Page />}
      />
      <Route
        path="/certificate-plant-operation-constructionthree"
        element={<CourseDetail115Page />}
      />
      <Route
        path="/certificate-plant-operation-constructionFour"
        element={<CourseDetail116Page />}
      />
      <Route
        path="/nvq-level-two-steel-erecting"
        element={<CourseDetail12Page />}
      />
      <Route
        path="/nvq-level-two-plant-operation"
        element={<CourseDetail13Page />}
      />
      <Route
        path="/nvq-level-two-fitted-interiors"
        element={<CourseDetail14Page />}
      />
      <Route
        path="/nvq-level-two-formwork-details"
        element={<CourseDetail15Page />}
      />
      <Route
        path="/nvq-level-two-diploma-formwork-details"
        element={<CourseDetail151Page />}
      />
      <Route
        path="/nvq-level-two-acoustic-Floor-Installation-Details"
        element={<CourseDetail16Page />}
      />
      <Route
        path="/nvq-level-two-Modular-Demountable"
        element={<CourseDetail161Page />}
      />
      <Route
        path="/nvq-level-two-Operable-partition-internal"
        element={<CourseDetail162Page />}
      />
      <Route
        path="/nvq-level-two-dry-lining-fixing"
        element={<CourseDetail163Page />}
      />
      <Route
        path="/nvq-level-two-interior-system"
        element={<CourseDetail164Page />}
      />
      <Route
        path="/nvq-level-two-access-flooring-details"
        element={<CourseDetail165Page />}
      />
      <Route
        path="/nvq-level-two-steel-fixing-occupations"
        element={<CourseDetail17Page />}
      />
      <Route
        path="/nvq-level-2-trowel-occupation"
        element={<CourseDetail18Page />}
      />
      <Route
        path="/nvq-level-two-wall-floor-tiling"
        element={<CourseDetail19Page />}
      />
      <Route
        path="/nvq-level-two-cladding-occupations"
        element={<CourseDetail20Page />}
      />
      <Route
        path="/nvq-level-two-roffing-occupations"
        element={<CourseDetail21Page />}
      />
      <Route
        path="/nvq-level-two-Plastering-occupation"
        element={<CourseDetail22Page />}
      />
      <Route
        path="/level-three-contaction-operations"
        element={<CourseDetailLevelThree1Page />}
      />
      <Route
        path="/nvq-level-three-wood-occupations"
        element={<CourseDetailLevelThree2Page />}
      />
      <Route
        path="/nvq-level-three-formwork"
        element={<CourseDetailLevelThree3Page />}
      />
      <Route
        path="/level-three-trowel-occupations"
        element={<CourseDetailLevelThree4Page />}
      />
      <Route
        path="/nvq-level-three-occupational-supervision"
        element={<CourseDetailLevelThree5Page />}
      />
      <Route
        path="/decorative-finishing-and-industrial-painting-occupations"
        element={<CourseDetailLevelThree6Page />}
      />
      <Route
        path="/building-and-civil-engineering"
        element={<CourseDetailLevelFour1Page />}
      />
      <Route
        path="/highways-maintenance-and-repair/details"
        element={<CourseDetailLevelFour1OnePage />}
      />
      <Route
        path="/residentialDevelopment"
        element={<CourseDetailLevelFour1TwoPage />}
      />
      <Route
        path="/traditional-And-Heritage-Building"
        element={<CourseDetailLevelFour1ThreePage />}
      />
      <Route path="/retrofit" element={<CourseDetailLevelFour1FourPage />} />
      <Route path="/demolition" element={<CourseDetailLevelFour1FivePage />} />
      <Route
        path="/nvq-level-six-consttruction-operations"
        element={<CourseDetailLevelSix1Page />}
      />
      <Route
        path="/level-six-construction-in-site-management"
        element={<CourseDetailLevelSix2Page />}
      />
      <Route
        path="/nvq-level-3-health-safety"
        element={<NvqHealthAndSafetyDetailPage1 />}
      />

      <Route
        path="/nvq-level-6-occupational-health-safety-practice"
        element={<NvqHealthAndSafetyDetailPage2 />}
      />
      <Route
        path="/nvq-level-three-buying-details"
        element={<CourseDetailLevelThree1OnePage />}
      />
      <Route
        path="/nvq-level-three-planning-details"
        element={<CourseDetailLevelThree1TwoPage />}
      />
      <Route
        path="/nvq-level-three-surveying-details"
        element={<CourseDetailLevelThree1ThreePage />}
      />
      <Route
        path="/nvq-level-three-general-details"
        element={<CourseDetailLevelThree1FourPage />}
      />
      <Route
        path="/nvq-level-three-site-technical-support-details"
        element={<CourseDetailLevelThree1FivePage />}
      />
      <Route
        path="/nvq-level-six-buying-patways"
        element={<CourseDetailLevelSix1OnePage />}
      />
      <Route
        path="/nvq-lvel-6-planning-pathway"
        element={<CourseDetailLevelSix1TwoPage />}
      />
      <Route
        path="/nvq-level-six-surveying-pathway"
        element={<CourseDetailLevelSix1ThreePage />}
      />
      <Route
        path="/nvq-level-six-general-pathway"
        element={<CourseDetailLevelSix1FourPage />}
      />
      <Route
        path="/nvq-level-six-retrofit"
        element={<CourseDetailLevelSix2OnePage />}
      />
      <Route
        path="/nvq-level-six-residential-dvelopment-pathway"
        element={<CourseDetailLevelSix2TwoPage />}
      />
      <Route
        path="/nvq-level-six-building-and--civil-engireering-Pathway"
        element={<CourseDetailLevelSix2ThreePage />}
      />
      <Route
        path="/nvq-level-six-tunneling"
        element={<CourseDetailLevelSix2FourPage />}
      />
      <Route
        path="/nvq-level-six-traditional-and-heritage-building"
        element={<CourseDetailLevelSix2FivePage />}
      />
      <Route path="/detail-page-1" element={<DetailPage1 />} />
      <Route path="/detail-page-2" element={<DetailPage2 />} />
      <Route path="/detail-page-3" element={<DetailPage3 />} />
      <Route path="/detail-page-4" element={<DetailPage4 />} />
      <Route path="/detail-page-5" element={<DetailPage5 />} />
      <Route path="/detail-page-6" element={<DetailPage6 />} />
      <Route path="/detail-page-7" element={<DetailPage7 />} />
      <Route path="/detail-page-8" element={<DetailPage8 />} />
      <Route path="/detail-page-9" element={<DetailPage9 />} />
      <Route path="/detail-page-10" element={<DetailPage10 />} />
      <Route path="/detail-page-11" element={<DetailPage11 />} />
      <Route path="/detail-page-12" element={<DetailPage12 />} />
      <Route path="/detail-page-13" element={<DetailPage13 />} />
      <Route path="/level-three-page" element={<LevelThreePage />} />
      <Route path="/level-four-page" element={<LevelFourPage />} />
      <Route path="/level-six-page" element={<LevelSixPage />} />
      <Route path="/level-seven-page" element={<LevelSevenPage />} />
      <Route path="/nvq-health-safety" element={<NvqHealthSafetyPage />} />
      <Route path="/nvq-level-3" element={<NVQLevel3Page />} />
      <Route path="/nvq-level-4" element={<NVQLevel4Page />} />
      <Route
        path="/nvq-level-6-contracting"
        element={<NVQLevel6DiplomaContractingPage />}
      />
      <Route
        path="/nvq-level-6-construction"
        element={<NVQLevel6DiplomaConstructionPage />}
      />
      <Route path="/citb-courses" element={<CITBPage />} />
      <Route path="/cscs-cards" element={<CSCSCardsPage />} />
      <Route path="/plant-operations" element={<PlantOperationsPage />} />
      <Route path="/contact-us" element={<ContactPage />} />
      <Route path="/about-us" element={<AboutUsPage />} />
      <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
      <Route path="/privacy-terms" element={<TermsAndConditionsPage />} />
      <Route path="/privacy-cookies" element={<CookiesPolicyPage />} />
      <Route path="/nvq-level-7" element={<NVQLevel7Page />} />
      <Route
        path="/citb-health-and-safety-policy"
        element={<CitbHealthAndSafetyPage />}
      />
      <Route path="/success" element={<PaymentSuccess />} />
      <Route path="/failed" element={<PaymentFailed />} />
      {/* redirected routes */}
      <Route path="/nvq-level-2-2" element={<LevelTwoPage />} />
      <Route
        path="/product/nvq-level-6-diploma-in-construction-site-management-building-and-civil-engineering-pathway"
        element={<LevelSixPage />}
      />
      <Route
        path="/nvq-level-3-diploma-in-occupational-work-supervision-construction"
        element={<LevelThreePage />}
      />
      <Route
        path="/nvq-level-7-construction-senior-management"
        element={<LevelSevenPage />}
      />
      <Route
        path="/Strategic-Health-and-Safety"
        element={<NVQLevel7StrategicHealthAndSafety />}
      />
      <Route path="/sssts-online" element={<SsstsOnlineTwoDays />} />
      <Route
        path="/sssts-online-refresher"
        element={<SsstsOnlineRefresherOneDay />}
      />
      <Route path="/smsts-online" element={<SmstsFiveDays />} />
      <Route
        path="/smsts-refresher-online"
        element={<SmstsRefresherOnlineTwoDays />}
      />
      <Route path="/thank-you" element={<ThankYouPage />} />
      <Route path="/user-info" element={<CampaignForm />} />
    </Route>
  )
);

const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById("root"));
if (process.env.NODE_ENV !== "development") {
  console.clear = () => {};
}
root.render(
  <React.StrictMode>
    <ChakraProvider theme={customTheme}>
      <QueryClientProvider client={queryClient}>
        <CartProvider>
          <RouterProvider router={router} />
        </CartProvider>
      </QueryClientProvider>
    </ChakraProvider>
  </React.StrictMode>
);

reportWebVitals();
