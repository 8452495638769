import { Box, Heading, Text } from "@chakra-ui/layout";
import axios from "axios";
import { useEffect } from "react";

const PaymentSuccess = () => {
  useEffect(() => {
    // Define your POST data
    const cartItems = JSON.parse(localStorage.getItem("cart")) || [];
    const client = JSON.parse(localStorage.getItem("client")) || [];
    let invoiceBody = {};
    invoiceBody["client"] = client._id;
    invoiceBody["items"] = [];
    invoiceBody["taxRate"] = 0;
    invoiceBody["date"] = new Date();
    invoiceBody["year"] = new Date().getFullYear();
    invoiceBody["expiredDate"] = new Date().getFullYear();
    invoiceBody["status"] = "sent";
    invoiceBody["note"] = "Fully paid";
    cartItems.forEach((item) => {
      invoiceBody["items"].push({
        _id: item._id,
        itemName: item.name,
        price: item.price,
        quantity: item.quantity,
        total: item.price * item.quantity,
        description: item.name,
        vat: item.vat,
      });
    });

    axios
      .post("/invoice/create", invoiceBody)
      .then((response) => {
        // Handle success response if neede
        axios
          .post("/invoice/mail", { id: response.data.result._id })
          .then((response) => {
            console.log(response.data);
          })
          .catch((err) => {
            console.log(err);
          });
        localStorage.removeItem("client")
        localStorage.removeItem("cart");
      })
      .catch((error) => {
        // Handle error response if needed
        console.error("Error making POST request", error);
      });
  }, []); // Empty dependency array ensures this effect runs only once, similar to componentDidMount

  return (
    <div>
      <Box textAlign="center" mt="20">
        <Heading color="green.500" mb="4">
          Payment Successful
        </Heading>
        <Text fontSize="xl">Thank you for your payment!</Text>
      </Box>
    </div>
  );
};

export default PaymentSuccess;
