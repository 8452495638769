import React, { useEffect } from "react";
import NavBar from "./components/navBar";
import Footer from "./components/footer";
import { Flex, Box } from "@chakra-ui/react";
import TopBar from "./components/topBar";
import { json, Outlet, useLocation } from "react-router-dom/dist";
import TawkToScript from "./TawkToScript";
import { useQuery } from "react-query";
import axios from "axios";
import CampaignForm from "./pages/campaignPage";

function App() {
  const location = useLocation();
  console.log(JSON.stringify(location, null, 2).pathname);

  const { isPending, error, data } = useQuery({
    queryKey: ["productCategory"],
    queryFn: () => axios.get(`productCategory/get-all`),
  });
  useEffect(() => {
    if (data && data.data && data.data.result) {
      const categoryData = {};

      data.data.result.forEach((category) => {
        const { _id, title } = category;
        categoryData[title] = _id;
      });

      localStorage.setItem("category", JSON.stringify(categoryData));
    }
  }, [data]);

  return (
    <div>
      {location.pathname === "/user-info" ? (
        <CampaignForm />
      ) : (
        <div>
          <Flex
            minH={"100vh"}
            flexDirection={"column"}
            fontFamily={"Barlow, sans-serif"}
          >
            <TawkToScript />
            <Box flex="1">
              <Box minH={"calc(100vh- 100px)"}>
                <TopBar />
                <NavBar />
                <main>
                  <Box>
                    <Outlet />
                  </Box>
                </main>
              </Box>
            </Box>
            <Box as="footer">
              <Footer />
            </Box>
          </Flex>
        </div>
      )}
    </div>
  );
}

export default App;
