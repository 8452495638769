import {
  Heading,
  Text,
  VStack,
  Button,
  UnorderedList,
  ListItem,
  Icon,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  useBreakpointValue,
  Flex,
} from "@chakra-ui/react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Link as ChakraLink } from "@chakra-ui/react";
import { FaShoppingCart } from "react-icons/fa";
import { MdOutlineFileDownload as Download } from "react-icons/md";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useQuery } from "react-query";
import { MdChevronRight } from "react-icons/md";
import AOS from "aos";
import ContactForm from "../contactForm";
import { useCart } from "../../../cart/addToCart";

function CourseDetailLevelThree6Page() {
  React.useEffect(() => {
    AOS.init({
      duration: 1200,
      easing: "ease-in-out-back",
    });
  }, []);
  const { addToCart } = useCart();

  const points = [
    "All remotely online portfolio",
    "FAST TRACK COMPLETION 8-12 weeks",
    "Leading to Gold CSCS card",
    "Instalment plans available",
  ];

  const { isPending, error, data } = useQuery(["product"], () => {
    return axios.get(`product/filter`, {
      params: {
        filter: "detailsPage",
        equal: "/decorative-finishing-and-industrial-painting-occupations",
      },
    });
  });

  const navigate = useNavigate();
  const AddToCart = (data) => {
    const { _id, name, price } = data;
    addToCart(
      { _id, name, price, vat: data.productCategory.vat, quantity: 1 },
      0
    );
    navigate("/checkout");
  };
  return (
    <Flex
      direction={useBreakpointValue({ base: "column", md: "row" })}
      bg="white"
      p={useBreakpointValue({ base: 2, md: 4 })}
      justify="space-between"
      px={useBreakpointValue({ base: 6, md: "4rem" })}
      maxW="150ch"
      gap={useBreakpointValue({ base: 2, md: 5 })}
      mx="auto"
      position="relative"
      minHeight="100vh"
    >
      <VStack
        spacing={4}
        align="stretch"
        w={useBreakpointValue({ base: "100%", md: "55%" })}
        px={useBreakpointValue({ base: 0, md: 10 })}
        my={useBreakpointValue({ base: 4, md: "3rem" })}
      >
        <Breadcrumb
          mb={5}
          fontSize="xs"
          fontWeight={500}
          spacing="4px"
          separator={<MdChevronRight color="gray.500" />}
        >
          <BreadcrumbItem>
            <BreadcrumbLink href="/">Home</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink href="/level-three-page">
              NVQ Level 3
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink href="/decorative-finishing-and-industrial-painting-occupations">
              Decorative Finishing and Industrial Painting Occupations
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Heading as="h1" size="lg" color="#941C1C" mb={3}>
          NVQ Level 3 Diploma in Decorative Finishing and Industrial Painting
          Occupations{" "}
        </Heading>
        <UnorderedList
          style={{
            listStyle: "circle",
            color: "black",
            marginBottom: 15,
          }}
        >
          {points.map((point, index) => (
            <ListItem key={index}>
              <Text>{point}</Text>
            </ListItem>
          ))}
        </UnorderedList>
        <Text fontSize="xl" fontWeight="600">
          £{data?.data.result[0].price} + VAT
        </Text>
        <Flex
          direction={useBreakpointValue({ base: "column", md: "row" })}
          justify="space-between"
          align="center"
          gap={useBreakpointValue({ base: 2, md: 5 })}
        >
          <Button
            onClick={() => AddToCart(data?.data.result[0])}
            w={useBreakpointValue({ base: "100%", md: "50%" })}
            borderRadius="2rem"
            color="white"
            bg="#941C1C"
            _hover={{ filter: "brightness(105%)" }}
            transition="all ease-in-out 200ms"
          >
            <Icon as={FaShoppingCart} mr={3} />
            Book Now
          </Button>
          <Button
            w={useBreakpointValue({ base: "100%", md: "50%" })}
            borderRadius="2rem"
            color="#941C1C"
            border="1px solid #941C1C"
            bg="transparent"
            _hover={{ filter: "brightness(105%)" }}
            transition="all ease-in-out 200ms"
          >
            <Icon as={Download} mr={3} />
            <ChakraLink
              href="https://www.proqualab.com/wp-content/uploads/2018/12/ProQual-L3-NVQ-Diploma-in-Decorative-Finishing-Painting-and-Decorating.pdf"
              isExternal
            >
              Course Specifications
            </ChakraLink>
          </Button>
        </Flex>
        <VStack mt={6} textAlign="left" justify="start" align="start">
          <Text fontWeight="bold">Overview</Text>
          <Text>
            The NVQ Level 3 Diploma in Decorative Finishing and Industrial
            Painting Occupations (Construction) is designed to recognize the
            knowledge, skills, and competence of individuals specializing in
            painting operations within the construction industry. This
            qualification enables candidates to achieve a CSCS Gold Card,
            enhancing professional recognition and opportunities.
          </Text>

          <Text fontWeight="bold">Entry Requirements</Text>
          <Text>
            Candidates must demonstrate the necessary skills, knowledge, and
            experience in painting and decorating roles.
          </Text>

          <Text fontWeight="bold">How long will my NVQ take to complete?</Text>
          <Text>
            The qualification is expected to be completed in approximately 6-8
            weeks, depending on the learner’s availability. The specific
            duration will be confirmed during the initial assessment and
            induction.
          </Text>

          <Text fontWeight="bold">Qualification Structure</Text>
          <Text>
            Candidates must complete the following mandatory units:
            <br />
            - Confirming work activities and resources <br /> - Developing and
            maintaining good occupational working relationships <br /> -
            Confirming the occupational method of work <br /> - Erecting and
            dismantling access/working platforms <br /> - Preparing surfaces for
            painting and/or decorating
            <br />
            - Applying surface coatings by brush and roller <br /> - Conforming
            to general health, safety, and welfare
          </Text>

          <Text fontWeight="bold">Industry recognition & benefits</Text>
          <Text>
            Upon completion, this NVQ qualifies the holder to apply for the
            Construction Skills Certification Scheme(CSCS){" "}
            <ChakraLink
              as={RouterLink}
              to={"/detail-page-4"}
              style={{ color: "blue", fontWeight: "bold" }}
            >
              Gold CSCS Card
            </ChakraLink>
          </Text>

          <Text fontWeight="bold">Process to Enrol and Complete</Text>
          <Text>
            - Initial Assessment: Evaluate your current skills, knowledge, and
            experience. <br />
            - Initial Assessment: Evaluate your current skills, knowledge, and
            experience. <br />- Portfolio Building: Complete and submit your
            portfolio online. <br />
            -Assessment: Your assessor will review your submitted work.
            <br /> - Completion: Achieve your qualification and apply for your
            CSCS Gold Card.
          </Text>

          <Text>
            <Text color={"red"} fontWeight={"bold"}>
              Contact Us
            </Text>
            For more information or to start your enrolment process, please get
            in touch with us. We are here to help you every step of the way.
          </Text>
        </VStack>
      </VStack>
      <ContactForm />
    </Flex>
  );
}

export default CourseDetailLevelThree6Page;
