import { FaShoppingCart } from "react-icons/fa";

import {
  Heading,
  Box,
  Text,
  Stack,
  Button,
  Image,
  useColorModeValue,
  Container,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { MdChevronRight } from "react-icons/md";
import { useQuery } from "react-query";
import axios from "axios";
import { useCart } from "./cart/addToCart";

export default function CitbHealthAndSafetyPage() {
  const { isPending, error, data } = useQuery(["product"], () => {
    return axios.get(`product/filter`, {
      params: {
        filter: "name",
        equal: "Book Your CITB Health and Safety Test with Us!",
      },
    });
  });
  const { addToCart } = useCart();
  const AddToCart = () => {
    console.log(data?.data.result[0]);
    addToCart({
      ...data?.data.result[0],
      quantity: 1,
      vat: data?.data.result[0].productCategory.vat,
    });
  };

  return (
    <Container maxW={"7xl"} p="12">
      <Breadcrumb
        mb={5}
        fontSize={"xs"}
        fontWeight={600}
        spacing="4px"
        separator={<MdChevronRight color="gray.500" />}>
        <BreadcrumbItem>
          <BreadcrumbLink href="/">Home</BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink href="/citb-health-and-safety-policy">
            CITB Health and Safety Test
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Heading as="h1" color={"#941C1C"}>
        CITB Health and Safety Test:
      </Heading>
      <Box
        marginTop={{ base: "1", sm: "5" }}
        display="flex"
        flexDirection={{ base: "column", sm: "row" }}
        justifyContent="space-between">
        <Box
          display="flex"
          flex="1"
          marginRight="3"
          position="relative"
          alignItems="center">
          <Box
            width={{ base: "100%", sm: "85%" }}
            zIndex="2"
            marginLeft={{ base: "0", sm: "5%" }}
            marginTop="5%"
            textDecoration="none"
            _hover={{ textDecoration: "none" }}>
            <Image
              borderRadius="lg"
              src={require("../assests/CitbPage/safety.jpg")}
              alt="Safety Image"
              objectFit="contain"
            />
          </Box>
          <Box zIndex="1" width="100%" position="absolute" height="100%">
            <Box
              bgGradient={useColorModeValue(
                "radial(orange.600 1px, transparent 1px)",
                "radial(orange.300 1px, transparent 1px)"
              )}
              backgroundSize="20px 20px"
              opacity="0.4"
              height="100%"
            />
          </Box>
        </Box>
        <Box
          display="flex"
          flex="1"
          flexDirection="column"
          justifyContent="center"
          marginTop={{ base: "3", sm: "0" }}>
          <Text as="p" marginTop="2" color={"#121212"} fontSize="2xl">
            <strong>{data?.data.result[0].title}</strong>
          </Text>
          <Text fontSize={{ base: "md", md: "lg" }} mb={4}>
            We simplify the entire process, our team of experts will call you
            and offer a test date and time that fits your schedule, giving you
            the flexibility to balance work and preparation
          </Text>
          <Stack direction={["column", "row"]} spacing={4}>
            <Button
              onClick={AddToCart}
              size={{ base: "md", md: "lg" }}
              borderRadius={"none"}
              color={"white"}
              backgroundColor="#941C1C"
              _hover={{ bg: "gray" }}
              px={{ base: 4, md: 8 }}
              transition="all 0.3s ease">
              £{data?.data.result[0].price}
              <FaShoppingCart style={{ marginLeft: "8px" }} />
            </Button>
            <Link to={"/contact-us"}>
              <Button
                size={{ base: "md", md: "lg" }}
                borderRadius={"none"}
                color={"white"}
                backgroundColor="#121212"
                _hover={{ bg: "gray" }}
                px={{ base: 4, md: 8 }}
                transition="all 0.3s ease">
                Enquire Now
              </Button>
            </Link>
          </Stack>
        </Box>
      </Box>
    </Container>
  );
}
