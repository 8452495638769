import {
  Heading,
  Text,
  VStack,
  Button,
  UnorderedList,
  ListItem,
  Icon,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
} from "@chakra-ui/react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Link as ChakraLink } from "@chakra-ui/react";
import { FaShoppingCart } from "react-icons/fa";
import { MdOutlineFileDownload as Download } from "react-icons/md";
import React from "react";
import { useQuery } from "react-query";
import { MdChevronRight } from "react-icons/md";
import AOS from "aos";
import ContactForm from "../contactForm";
import { useCart } from "../../../cart/addToCart";
import axios from "axios";

function CourseDetail1Page() {
  React.useEffect(() => {
    AOS.init({
      duration: 1200,
      easing: "ease-in-out-back",
    });
  }, []);
  const points = [
    "All remotely online portfolio",
    "Fast track completion",
    "Leading to Blue CSCS card",
    "Instalment plans available",
  ];
  const { isPending, error, data } = useQuery(["product"], () => {
    return axios.get(`product/filter`, {
      params: {
        filter: "detailsPage",
        equal: "/nvq-level-two-industrial-painter",
      },
    });
  });
  const { addToCart } = useCart();

  const navigate = useNavigate();
  const AddToCart = (data) => {
    const { _id, name, price } = data;
    addToCart(
      { _id, name, price, vat: data.productCategory.vat, quantity: 1 },
      0
    );
    navigate("/checkout");
  };
  return (
    <Flex
      direction={{ base: "column", md: "row" }}
      bg="white"
      p={2}
      justify={"space-between"}
      px={{ base: 4, md: "4rem" }}
      maxW={"150ch"}
      gap={2}
      mx="auto"
      position="relative"
      minHeight="100vh"
    >
      <VStack
        spacing={4}
        align="stretch"
        w={{ base: "100%", md: "55%" }}
        px={{ base: 0, md: 10 }}
        my={{ base: 4, md: "3rem" }}
      >
        <Breadcrumb
          mb={5}
          fontSize={"xs"}
          fontWeight={500}
          spacing="4px"
          separator={<MdChevronRight color="gray.500" />}
        >
          <BreadcrumbItem>
            <BreadcrumbLink href="/">Home</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink href="/level-two-page">NVQ Level 2</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink href="/nvq-level-two-industrial-painter">
              Industrial Painter
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Heading as="h1" size="lg" color="#941C1C" mb={3}>
          NVQ Level 2 Diploma in Decorative Finishing and Industrial painting
          Occupations – Industrial Painter
        </Heading>
        <UnorderedList
          style={{
            listStyle: "circle",
            color: "black",
            marginBottom: 15,
          }}
        >
          {points.map((point, index) => (
            <ListItem key={index}>
              <Text>{point}</Text>
            </ListItem>
          ))}
        </UnorderedList>
        <Text fontSize={"xl"} fontWeight={"600"}>
          £{data?.data.result[0].price} + VAT
        </Text>
        <Flex
          direction={{ base: "column", md: "row" }}
          justify={"space-between"}
          align={"center"}
          gap={2}
        >
          <Button
            w={{ base: "100%", md: "50%" }}
            borderRadius={"2rem"}
            color={"white"}
            bg={"#941C1C"}
            _hover={{ filter: "brightness(105%)" }}
            onClick={() => {
              AddToCart(data?.data.result[0]);
            }}
          >
            <Icon as={FaShoppingCart} mr={3} />
            Book Now
          </Button>
          <Button
            w={{ base: "100%", md: "50%" }}
            borderRadius={"2rem"}
            color={"#941C1C"}
            border={"1px solid #941C1C"}
            bg="transparent"
            _hover={{ filter: "brightness(105%)" }}
          >
            <Icon as={Download} mr={3} />
            <ChakraLink
              href="https://www.proqualab.com/wp-content/uploads/2016/10/ProQual-L2-NVQ-Diploma-in-Decorative-Finishing-and-Industrial-Painting-Occs-July-2020.pdf"
              isExternal
            >
              Course Specifications
            </ChakraLink>
          </Button>
        </Flex>
        <VStack mt={6} textAlign={"left"} justify={"start"} align={"start"}>
          <Text fontWeight="bold">Entry Requirements</Text>
          <Text>
            An initial assessment plan will be carried out to identify your
            skills, experience and discuss the assessment plan. You must have
            previous experience in a painting and decorating role and have
            sufficient and suitable knowledge before you undertake this NVQ.
          </Text>

          <Text fontWeight="bold">How long will my NVQ take to complete?</Text>
          <Text>
            An assessment plan will be agreed during your Initial
            assessment/Induction and a planned end date agreed. However, we
            would expect your qualification to be complete within 8 weeks
            depending on the learner’s time availability.
          </Text>

          <Text fontWeight="bold">Assessment contents & units</Text>
          <Text>
            Candidates can follow one of three Pathways relating to their work
            roles:
            <br />
            - Pathway 1: Painter
            <br />
            - Pathway 2: Industrial Painter
            <br />- Pathway 3: Heritage Painter
          </Text>

          <Text fontWeight="bold">Industry recognition & benefits</Text>
          <Text>
            NVQ Level 2 Diploma in Decorative Finishing and Industrial Painting
            Occupations qualifies the holder to apply for the Construction
            Skills Certification Scheme (CSCS){" "}
            <ChakraLink
              as={RouterLink}
              to={"/detail-page-3"}
              style={{ color: "blue", fontWeight: "bold" }}
            >
              Blue CSCS Card
            </ChakraLink>
          </Text>
        </VStack>
      </VStack>
      <ContactForm />
    </Flex>
  );
}

export default CourseDetail1Page;
