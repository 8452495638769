// Simple:

// This file is currently not active.
// File inactive.
// Technical:

// This file is closed and not being accessed.
// Process ID: N/A (indicating no process is currently using the file)
// Descriptive:

// This file is available for use.
// Ready for further processing.
// Humorous (avoid if inappropriate):

// Taking a nap. Please don't wake me up. (best for informal settings)
// Gone fishing! Be back soon. (only for very casual use)

import {
  Heading,
  Text,
  Link,
  VStack,
  Button,
  UnorderedList,
  ListItem,
  Icon,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  useBreakpointValue,
  Flex,
} from "@chakra-ui/react";
import { FaShoppingCart } from "react-icons/fa";
import React, { useEffect, useState } from "react";

import { MdChevronRight } from "react-icons/md";
import ContactForm from "./course-details/contactForm";
import { useLocation, useNavigate } from "react-router-dom";
import { useCart } from "../cart/addToCart";

function NVQPage() {
  const { useCart } = useCart();

  const points = [
    "Remotely – ONLINE PORTFOLIO",
    "FAST TRACK COMPLETION 6-8 weeks",
    "Instalment payment plans available",
    "Leading to GOLD CSCS Card",
  ];
  const [price, setPrice] = useState(0);
  const [name, setName] = useState("");

  const detailsPage = JSON.parse(localStorage.getItem("details-page")) || {};

  const location = useLocation();
  useEffect(() => {
    if (location?.state._id && location.state.path) {
      const stateString = location.state;
      detailsPage[stateString.path] = stateString;
      setPrice(stateString.price);
      setName(stateString.name);
      localStorage.setItem("details-page", JSON.stringify(detailsPage));
    } else {
      setPrice(detailsPage["/level-three-detail-page-5"].price);
      setName(detailsPage["/level-three-detail-page-5"].name);
    }
  }, []);
  const { addToCart } = useCart();
  const navigate = useNavigate();
  const AddToCart = () => {
    addToCart({ name, price, quantity: 1 }, 0);
    navigate("/checkout");
  };
  return (
    <Flex
      direction={{ base: "column", md: "row" }}
      bg="white"
      p={{ base: 2, md: 4 }}
      justify={"space-between"}
      px={{ base: 6, md: "4rem" }}
      maxW={"150ch"}
      gap={{ base: 2, md: 5 }}
      mx="auto"
      position="relative"
      minHeight="100vh"
    >
      <VStack
        spacing={4}
        align="stretch"
        w={{ base: "100%", md: "55%" }}
        px={{ base: 0, md: 10 }}
        my={{ base: 4, md: "3rem" }}
      >
        <Breadcrumb
          mb={5}
          fontSize={"xs"}
          fontWeight={500}
          spacing="4px"
          separator={<MdChevronRight color="gray.500" />}
        >
          <BreadcrumbItem>
            <BreadcrumbLink href="/">Home</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink href="/nvq-level-3">NVQ Level 3</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Heading as="h1" size="lg" color="#941C1C" mb={3}>
          NVQ Level 3 Diploma in Occupational Work Supervision
        </Heading>
        <UnorderedList
          style={{
            listStyle: "circle",
            color: "black",
            marginBottom: 15,
          }}
        >
          {points.map((point, index) => (
            <ListItem key={index}>
              <Text>{point}</Text>
            </ListItem>
          ))}
        </UnorderedList>
        <Text fontSize={"xl"} fontWeight={"600"}>
          £{price} + VAT
        </Text>
        <Flex
          direction={{ base: "column", md: "row" }}
          justify={"space-between"}
          align={"center"}
          gap={{ base: 2, md: 5 }}
        >
          <Button
            onClick={AddToCart}
            w={{ base: "100%", md: "50%" }}
            borderRadius={"2rem"}
            color={"white"}
            bg={"#941C1C"}
            _hover={{ filter: "brightness(105%)" }}
            transition={"all ease-in-out 200ms"}
          >
            <Icon as={FaShoppingCart} mr={3} />
            Book Now
          </Button>
        </Flex>
        <VStack mt={6} textAlign={"left"} justify={"start"} align={"start"}>
          <Text>
            The Level 3 NVQ Diploma in Occupational Work Supervision is designed
            to assess occupational competence in the workplace. Learners are
            required to demonstrate their skills, knowledge, and experience in
            the supervision of their colleagues. This qualification is suitable
            for gangers, supervisors responsible for carpenters, bricklayers,
            plumbers, in fact, any trade and provides formal recognition of
            practicing as a trade supervisor.
          </Text>

          <Text fontWeight="bold">Entry Requirements</Text>
          <Text>
            You must be able to demonstrate that you can meet the skills,
            knowledge and experience requirements of the NVQ before enrolment.
          </Text>

          <Text fontWeight="bold">How long will my NVQ take to complete?</Text>
          <Text>
            An assessment plan will be agreed during your Initial
            assessment/Induction and a planned end date agreed. However, we
            would expect your qualification to be complete with 8 weeks
            depending on the learner’s time availability.
          </Text>

          <Text fontWeight="bold">Assessment contents & units</Text>

          <Text>
            Mandatory Units:
            <br />
            1. Confirming work activities and resources for an occupational work
            area in the workplace
            <br />
            2. Developing and maintaining good occupational working
            relationships in the workplace
            <br />
            3. Confirming the occupational method of work in the workplace
            <br />- 4. Co-ordinating and organising work operations in the
            workplace
            <Text
              style={{ color: "#121212", marginTop: 7, fontWeight: "bold" }}
            >
              Optional Units (two from)
            </Text>
            <br />
            1. Allocating and monitoring the use of plant and equipment in the
            workplace
            <br />
            2. Monitoring progress of work against schedules in the workplace
            <br />
            3. Confirming work meets quality standards in the workplace
            <br />
            4. Implementing procedures to support the team’s performance in the
            workplace
            <br />
            5. Implementing Communication Systems for Construction Projects in
            the Workplace
            <br />
            6. Co-ordinating and confirming dimensional control requirements of
            the work in the workplace
          </Text>

          <Text fontWeight="bold">Industry recognition & benefits</Text>
          <Text>
            The NVQ Diploma in Occupational Work Supervision qualifies the
            holder to apply for the Construction Skills Certification Scheme
            (CSCS) gold card{" "}
            <Link
              href="https://www.cscs.uk.com/card-type/supervisory/"
              color="blue.500"
              isExternal
            >
              CSCS Card
            </Link>
          </Text>
        </VStack>
      </VStack>
      <ContactForm />
    </Flex>
  );
}

export default NVQPage;
