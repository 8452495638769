import { Box, Heading, Text } from "@chakra-ui/layout";
import React from "react";

const PaymentFailed = () => {
  return (
    <div>
      <Box textAlign="center" mt="20">
        <Heading color="red.500" mb="4">
          Payment Failed
        </Heading>
        <Text fontSize="xl">Oops! Something went wrong with your payment.</Text>
      </Box>
    </div>
  );
};

export default PaymentFailed;
