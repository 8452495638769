import {
  Heading,
  Text,
  VStack,
  Button,
  Icon,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
} from "@chakra-ui/react";
import { FaShoppingCart } from "react-icons/fa";
import React from "react";
import { useQuery } from "react-query";
import { MdChevronRight } from "react-icons/md";
import ContactForm from "../nvqPages/course-details/contactForm";
import { useCart } from "../cart/addToCart";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

function SmstsRefresherOnlineTwoDays() {
  const { isPending, error, data } = useQuery(["product"], () => {
    return axios.get(`product/filter`, {
      params: {
        filter: "detailsPage",
        equal: "/smsts-refresher-online",
      },
    });
  });
  const { addToCart } = useCart();

  const navigate = useNavigate();

  const AddToCart = (data) => {
    const { _id, name, price } = data;
    addToCart(
      { _id, name, price, vat: data.productCategory.vat, quantity: 1 },
      0
    );
    navigate("/checkout");
  };
  return (
    <Flex
      direction={{ base: "column", md: "row" }}
      bg="white"
      p={2}
      justify={"space-between"}
      px={{ base: 4, md: "4rem" }}
      maxW={"150ch"}
      gap={2}
      mx="auto"
      position="relative"
      minHeight="100vh"
    >
      <VStack
        spacing={4}
        align="stretch"
        w={{ base: "100%", md: "55%" }}
        px={{ base: 0, md: 10 }}
        my={{ base: 4, md: "3rem" }}
      >
        <Breadcrumb
          mb={5}
          fontSize={"xs"}
          fontWeight={500}
          spacing="4px"
          separator={<MdChevronRight color="gray.500" />}
        >
          <BreadcrumbItem>
            <BreadcrumbLink href="/">Home</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink href="/citb-courses">CITB Courses</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink href="/smsts-refresher-online">
              SMSTS
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Heading as="h1" size="lg" color="#941C1C" mb={3}>
          The SMSTS Refresher course is a 2-day site management training course
        </Heading>

        <Text fontSize={"xl"} fontWeight={"600"}>
          £{data?.data.result[0].price} + VAT
        </Text>
        <Flex
          direction={{ base: "column", md: "row" }}
          justify={"space-between"}
          align={"center"}
          gap={2}
        >
          <Button
            w={{ base: "100%", md: "50%" }}
            borderRadius={"2rem"}
            color={"white"}
            bg={"#941C1C"}
            _hover={{ filter: "brightness(105%)" }}
            onClick={() => {
              AddToCart(data?.data.result[0]);
            }}
          >
            <Icon as={FaShoppingCart} mr={3} />
            Book Now
          </Button>
        </Flex>
        <VStack mt={6} textAlign={"left"} justify={"start"} align={"start"}>
          <Text>
            The SMSTS Refresher course is a 2-day site management training
            course for delegates who have previously passed the full 5 day{" "}
            <Link
              to={
                "https://www.bookmycourse.co.uk/courses/citb_smsts_site_management_safety_training_scheme_347/"
              }
            >
              Site Management Safety Training Scheme (SMSTS) CITB course
            </Link>
            . Candidates must be able to provide proof of attendance to the full
            SMSTS course to be eligible for the two-day SMSTS Refresher course.
          </Text>
          <Text>
            Successful delegates will receive a CITB SMSTS certificate which
            will be valid for 5 years.
          </Text>
          <Text fontWeight={"bold"}>
            Please note that the candidate's current SMSTS OR SMSTS Refresher
            certificate must not have expired; if this is the case, the
            candidate will need to re-take the full five-day SMSTS course. 
          </Text>
          <Text fontWeight="bold">CITB Course Detail </Text>
          <Text>
            This CITB SMSTS Refresher course aims to bring the candidate’s
            health and safety knowledge up to date in line with current
            legislature, as well as covering any changes to legislation and
            their impact on the workplace thoroughly.
            <Text>This CITB course includes the following elements:</Text>
            <br />
            - Recent changes to legislation surrounding construction, health and
            safety, welfare and environmental issues.
            <br />
            - The Health and Safety at Work Act, CDM, new regulations and
            regulations updates within the construction industry. Latest HSE
            Guidance, ACoP updates and HSE initiatives, which impact on site
            safety and best practices
            <br />- An overview of proposed or impending legislation Latest
            accident statistics, costs of accidents, causes, and management
            responsibilities and methods of prevention.
            <br />- Methods for motivating the workforce towards safety
            behaviours, tool box talks and site inductions.
          </Text>
          <Text>
            Full attendance is mandatory, and candidates will be continually
            assessed during the course and be required to participate in
            syndicate exercises during the course. There is a multiple-choice
            assessment at the end of the course.
          </Text>
          <Text>
            Successful candidates will receive the CITB SMSTS certificate, which
            is issued on completion and is valid for 5 years.{" "}
          </Text>
        </VStack>
      </VStack>
      <ContactForm />
    </Flex>
  );
}

export default SmstsRefresherOnlineTwoDays;
