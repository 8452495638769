import {
  Heading,
  Text,
  VStack,
  Button,
  UnorderedList,
  Link,
  ListItem,
  Icon,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  useBreakpointValue,
  Flex,
} from "@chakra-ui/react";
import { FaShoppingCart } from "react-icons/fa";
import { MdOutlineFileDownload as Download } from "react-icons/md";
import React from "react";
import axios from "axios";
import { useQuery } from "react-query";
import { MdChevronRight } from "react-icons/md";
import AOS from "aos";
import ContactForm from "../contactForm";
import { useCart } from "../../../cart/addToCart";
import { useNavigate } from "react-router-dom";

function CourseDetail112Page() {
  React.useEffect(() => {
    AOS.init({
      duration: 1200,
      easing: "ease-in-out-back",
    });
  }, []);
  const { addToCart } = useCart();

  const points = [
    "All remotely online portfolio",
    "Fast track completion 8 weeks",
    "Instalment plans available",
    "Leading to blue CSCS Card",
  ];
  const { isPending, error, data } = useQuery(["product"], () => {
    return axios.get(`product/filter`, {
      params: {
        filter: "detailsPage",
        equal: "/certificate-plant-operation-construction",
      },
    });
  });

  const navigate = useNavigate();
  const AddToCart = (data) => {
    const { _id, name, price } = data;
    addToCart(
      { _id, name, price, vat: data.productCategory.vat, quantity: 1 },
      0
    );
    navigate("/checkout");
  };
  return (
    <Flex
      direction={useBreakpointValue({ base: "column", md: "row" })}
      bg="white"
      p={useBreakpointValue({ base: 2, md: 4 })}
      justify={"space-between"}
      px={useBreakpointValue({ base: 6, md: "4rem" })}
      maxW={"150ch"}
      gap={useBreakpointValue({ base: 2, md: 5 })}
      mx="auto"
      position="relative"
      minHeight="100vh"
    >
      <VStack
        spacing={4}
        align="stretch"
        w={useBreakpointValue({ base: "100%", md: "55%" })}
        px={useBreakpointValue({ base: 0, md: 10 })}
        my={useBreakpointValue({ base: 4, md: "3rem" })}
      >
        <Breadcrumb
          mb={5}
          fontSize={"xs"}
          fontWeight={500}
          spacing="4px"
          separator={<MdChevronRight color="gray.500" />}
        >
          <BreadcrumbItem>
            <BreadcrumbLink href="/">Home</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink href="/level-two-page">NVQ Level 2</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink href="/certificate-plant-operation-construction">
              Fork Lift
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Heading as="h1" size="lg" color="#941C1C" mb={3}>
          NVQ L2 Certificate in Plant Operations – Fork Lift Truck
        </Heading>
        <UnorderedList
          style={{
            listStyle: "circle",
            color: "black",
            marginBottom: 15,
          }}
        >
          {points.map((point, index) => (
            <ListItem key={index}>
              <Text>{point}</Text>
            </ListItem>
          ))}
        </UnorderedList>
        <Text fontSize={"xl"} fontWeight={"600"}>
          £{data?.data.result[0].price} + VAT
        </Text>
        <Flex
          direction={useBreakpointValue({ base: "column", md: "row" })}
          justify={"space-between"}
          align={"center"}
          gap={useBreakpointValue({ base: 2, md: 5 })}
        >
          <Button
            onClick={() => {
              AddToCart(data?.data.result[0]);
            }}
            w={useBreakpointValue({ base: "100%", md: "50%" })}
            borderRadius={"2rem"}
            color={"white"}
            bg={"#941C1C"}
            _hover={{ filter: "brightness(105%)" }}
            transition={"all ease-in-out 200ms"}
          >
            <Icon as={FaShoppingCart} mr={3} />
            Book Now
          </Button>
          <Button
            w={useBreakpointValue({ base: "100%", md: "50%" })}
            borderRadius={"2rem"}
            color={"#941C1C"}
            border={"1px solid #941C1C"}
            bg="transparent"
            _hover={{ filter: "brightness(105%)" }}
            transition={"all ease-in-out 200ms"}
          >
            <Icon as={Download} mr={3} />
            <Link
              href="https://www.proqualab.com/wp-content/uploads/2019/07/ProQual-Level-2-NVQ-DIploma-Controlling-Lifting-Operations-Slinger-Signaller-April-2019.pdf"
              isExternal
            >
              Course Specifications
            </Link>
          </Button>
        </Flex>
        <VStack mt={6} textAlign={"left"} justify={"start"} align={"start"}>
          <Text fontWeight="bold">About this NVQ assessment </Text>
          <Text>
            NVQ Level 2 Certificate in Plant Operations qualification provides a
            nationally recognised qualification for plant operatives following
            one of 15 Pathways relating to their work roles:
            <UnorderedList>
              <ListItem>
                <Text>
                  - Pathway 1: Level 2 NVQ Certificate in Plant Operations
                  (Cranes and Specialist Lifting)
                </Text>
              </ListItem>
              <ListItem>
                <Text>
                  - Pathway 2: Level 2 NVQ Certificate in Plant Operations
                  (Fork-lift Trucks)
                </Text>
              </ListItem>
              <ListItem>
                <Text>
                  - Pathway 3: Level 2 NVQ Certificate in Plant Operations
                  (Extracting)
                </Text>
              </ListItem>
              <ListItem>
                <Text>
                  - Pathway 4: Level 2 NVQ Certificate in Plant Operations
                  (Transporting Loads)
                </Text>
              </ListItem>
              <ListItem>
                <Text>
                  - Pathway 5: Level 2 NVQ Certificate in Plant Operations (Work
                  Platforms)
                </Text>
              </ListItem>
              <ListItem>
                <Text>
                  - Pathway 6: Level 2 NVQ Certificate in Plant Operations
                  (Laying and Distributing)
                </Text>
              </ListItem>
              <ListItem>
                <Text>
                  - Pathway 7: Level 2 NVQ Certificate in Plant Operations
                  (Compacting)
                </Text>
              </ListItem>
              <ListItem>
                <Text>
                  - Pathway 8: Level 2 NVQ Certificate in Plant Operations
                  (Processing)
                </Text>
              </ListItem>
              <ListItem>
                <Text>
                  - Pathway 9: Level 2 NVQ Certificate in Plant Operations
                  (Loading and Securing)
                </Text>
              </ListItem>
              <ListItem>
                <Text>
                  - Pathway 10: Level 2 NVQ Certificate in Plant Operations
                  (Road/Rail)
                </Text>
              </ListItem>
              <ListItem>
                <Text>
                  - Pathway 11: Level 2 NVQ Certificate in Plant Operations
                  (Attachments) 
                </Text>
              </ListItem>
              <ListItem>
                <Text>
                  - Pathway 12: Level 2 NVQ Certificate in Plant Operations
                  (Excavating) 
                </Text>
              </ListItem>
              <ListItem>
                <Text>
                  - Pathway 13: Level 2 NVQ Certificate in Plant Operations
                  (Sweeping, Cleaning, Clearing)
                </Text>
              </ListItem>
              <ListItem>
                <Text>
                  - Pathway 14: Level 2 NVQ Certificate in Plant Operations
                  (Operations Guide)
                </Text>
              </ListItem>
              <ListItem>
                <Text>
                  - Pathway 15: Level 2 NVQ Certificate in Plant Operations
                  (Movement Guide)
                </Text>
              </ListItem>
            </UnorderedList>
          </Text>
          <Text fontWeight="bold">Entry Requirements</Text>
          <Text>
            You must be able to demonstrate that you can meet the skills,
            knowledge and experience requirements of the NVQ before enrolment.
          </Text>

          <Text fontWeight="bold">How long will my NVQ take to complete?</Text>
          <Text>
            An assessment plan will be agreed during your Initial
            assessment/Induction and a planned end date agreed. However, we
            would expect your qualification to be complete with 8 weeks
            depending on the learners time availability.
          </Text>

          <Text fontWeight="bold">Qualification Structure</Text>
          <Text>
            To achieve the qualification candidates must complete the two
            Mandatory units plus the required Mandatory and/or Optional Units
            from one of the Pathways.
            <Text fontWeight="bold">Mandatory units for all pathways</Text>
            <br />
            - Conforming to general health, safety and welfare in the workplace
            <br />- Conforming to productive working practices in the workplace
          </Text>

          <Text fontWeight="bold">Industry recognition & benefits</Text>
          <Text>
            NVQ Level 2 Diploma in Interior Systems qualifies the holder to
            apply for the Construction Skills Certification Scheme (CSCS)
            <Link
              to={"/detail-page-3"}
              style={{ color: "blue", fontWeight: "bold" }}
            >
              Blue CSCS Card
            </Link>
          </Text>
        </VStack>
      </VStack>
      <ContactForm />
    </Flex>
  );
}

export default CourseDetail112Page;
