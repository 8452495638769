import {
  Heading,
  Text,
  VStack,
  Button,
  Icon,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
} from "@chakra-ui/react";
import { FaShoppingCart } from "react-icons/fa";
import React from "react";
import { useQuery } from "react-query";
import { MdChevronRight } from "react-icons/md";
import ContactForm from "../nvqPages/course-details/contactForm";
import { useCart } from "../cart/addToCart";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

function SsstsOnlineTwoDays() {
  const { isPending, error, data } = useQuery(["product"], () => {
    return axios.get(`product/filter`, {
      params: {
        filter: "detailsPage",
        equal: "/sssts-online",
      },
    });
  });
  const { addToCart } = useCart();
  const navigate = useNavigate();
  const AddToCart = (data) => {
    const { _id, name, price } = data;
    addToCart(
      { _id, name, price, vat: data.productCategory.vat, quantity: 1 },
      0
    );
    navigate("/checkout");
  };
  return (
    <Flex
      direction={{ base: "column", md: "row" }}
      bg="white"
      p={2}
      justify={"space-between"}
      px={{ base: 4, md: "4rem" }}
      maxW={"150ch"}
      gap={2}
      mx="auto"
      position="relative"
      minHeight="100vh"
    >
      <VStack
        spacing={4}
        align="stretch"
        w={{ base: "100%", md: "55%" }}
        px={{ base: 0, md: 10 }}
        my={{ base: 4, md: "3rem" }}
      >
        <Breadcrumb
          mb={5}
          fontSize={"xs"}
          fontWeight={500}
          spacing="4px"
          separator={<MdChevronRight color="gray.500" />}
        >
          <BreadcrumbItem>
            <BreadcrumbLink href="/">Home</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink href="/citb-courses">CITB Courses</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink href="/sssts-online">SMSTS</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Heading as="h1" size="lg" color="#941C1C" mb={3}>
          SSSTS Refresher course is one of the newer additions to the Site
          Safety Plus range of courses
        </Heading>

        <Text fontSize={"xl"} fontWeight={"600"}>
          £{data?.data.result[0].price} + VAT
        </Text>
        <Flex
          direction={{ base: "column", md: "row" }}
          justify={"space-between"}
          align={"center"}
          gap={2}
        >
          <Button
            w={{ base: "100%", md: "50%" }}
            borderRadius={"2rem"}
            color={"white"}
            bg={"#941C1C"}
            _hover={{ filter: "brightness(105%)" }}
            onClick={() => {
              AddToCart(data?.data.result[0]);
            }}
          >
            <Icon as={FaShoppingCart} mr={3} />
            Book Now
          </Button>
        </Flex>
        <VStack mt={6} textAlign={"left"} justify={"start"} align={"start"}>
          <Text>
            The 2-day SSSTS (Site Supervisors Safety Training Scheme) training
            course is one of the courses that form part of the CITB  Site Safety
            Plus range of courses and is available via a network of CITB
            accredited providers.  It is aimed at team leaders, foremen,
            gangers, first line managers, and supervisors with supervisory
            responsibilities and a need to understand their legal duties.
          </Text>
          <Text>
            Also known informally as the ‘Site Supervisor Course’, the training
            given is aimed to ensure candidates gain a thorough understanding of
            their legal responsibilities, and as such can better contribute to
            the instruction, supervision and monitoring of safety on site.
          </Text>
          <Text>
            Successful candidates will receive a CITB Site Safety Plus
            certificate, which is issued on completion. This is valid for 5
            years. Please note that there is no physical ‘SSSTS Card’, however,
            you can list your Site Safety Plus certificate qualification when
            applying for further qualifications.
          </Text>
          <Text fontWeight={"bold"}>
            If you have already passed the 2-day CITB SSSTS course and need to
            renew your CITB certificate, you will be eligible for the 1-day{" "}
            <Link to="https://www.bookmycourse.co.uk/courses/citb_sssts_refresher_courses_386/">
              SSSTS refresher
            </Link>
            , providing your certificate is still in date and valid.
          </Text>

          <Text fontWeight="bold">CITB Course Detail </Text>
          <Text>
            The SSSTS course is an ideal foundation for attaining a basic
            understanding of health & safety duties and responsibilities for
            junior management in construction work.
            <Text>This CITB course includes the following elements: </Text>
            <br />
            - An introduction into health and safety on site, as well as welfare
            and environmental issues that those in a supervisory role must be
            aware of. This also includes the legal responsibilities that are
            relevant to supervisors whilst safely managing a construction site
            <br />
            - What is expected of site supervisors, including occupational
            health issues that arise within the construction sector
            <br />- An understanding of how health and safety law is structured,
            and how the role of a supervisor links in with the role of a site
            manager when controlling site safety
            <br />- Risk assessments – how to carry these out and why method
            statements are a legal requirement
            <br />
            -Fire prevention and control, control of hazardous substances, CDM
            (Construction Design Management) Regulations, Working at Height, PPE
            (Personal Protective Equipment), manual handling, accidents and
            first aid
            <br />- How to carry out site inductions, toolbox talks and method
            statement briefings
            <br />- Understanding the need for proactive and reactive monitoring
          </Text>
          <Text>
            Full attendance is essential, and candidates will be expected to
            actively participate. Continual assessment will take place during
            the course. There is also a multiple-choice assessment at the end of
            the course.
          </Text>
        </VStack>
      </VStack>
      <ContactForm />
    </Flex>
  );
}

export default SsstsOnlineTwoDays;
