import {
  Heading,
  Text,
  VStack,
  Button,
  UnorderedList,
  ListItem,
  Icon,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  useBreakpointValue,
  Flex,
} from "@chakra-ui/react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Link as ChakraLink } from "@chakra-ui/react";
import { FaShoppingCart } from "react-icons/fa";
import { MdOutlineFileDownload as Download } from "react-icons/md";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useQuery } from "react-query";
import { MdChevronRight } from "react-icons/md";
import AOS from "aos";
import ContactForm from "../contactForm";
import { useCart } from "../../../cart/addToCart";

function CourseDetailLevelThree4Page() {
  React.useEffect(() => {
    AOS.init({
      duration: 1200,
      easing: "ease-in-out-back",
    });
  }, []);

  const points = [
    "All remotely online portfolio",
    "FAST TRACK COMPLETION 8-12 weeks",
    "Leading to Gold CSCS card",
    "Instalment plans available",
  ];
  const { isPending, error, data } = useQuery(["product"], () => {
    return axios.get(`product/filter`, {
      params: {
        filter: "detailsPage",
        equal: "/level-three-trowel-occupations",
      },
    });
  });
  const { addToCart } = useCart();
  const navigate = useNavigate();
  const AddToCart = (data) => {
    const { _id, name, price } = data;
    addToCart(
      { _id, name, price, vat: data.productCategory.vat, quantity: 1 },
      0
    );
    navigate("/checkout");
  };

  return (
    <Flex
      direction={useBreakpointValue({ base: "column", md: "row" })}
      bg="white"
      p={useBreakpointValue({ base: 2, md: 4 })}
      justify="space-between"
      px={useBreakpointValue({ base: 6, md: "4rem" })}
      maxW="150ch"
      gap={useBreakpointValue({ base: 2, md: 5 })}
      mx="auto"
      position="relative"
      minHeight="100vh"
    >
      <VStack
        spacing={4}
        align="stretch"
        w={useBreakpointValue({ base: "100%", md: "55%" })}
        px={useBreakpointValue({ base: 0, md: 10 })}
        my={useBreakpointValue({ base: 4, md: "3rem" })}
      >
        <Breadcrumb
          mb={5}
          fontSize="xs"
          fontWeight={500}
          spacing="4px"
          separator={<MdChevronRight color="gray.500" />}
        >
          <BreadcrumbItem>
            <BreadcrumbLink href="/">Home</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink href="/level-three-page">
              NVQ Level 3
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink href="/level-three-trowel-occupations">
              Bricklaying
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Heading as="h1" size="lg" color="#941C1C" mb={3}>
          NVQ Level 3 Diploma in Trowel Occupations -Bricklaying
        </Heading>
        <UnorderedList
          style={{
            listStyle: "circle",
            color: "black",
            marginBottom: 15,
          }}
        >
          {points.map((point, index) => (
            <ListItem key={index}>
              <Text>{point}</Text>
            </ListItem>
          ))}
        </UnorderedList>
        <Text fontSize="xl" fontWeight="600">
          £{data?.data.result[0].price} + VAT
        </Text>
        <Flex
          direction={useBreakpointValue({ base: "column", md: "row" })}
          justify="space-between"
          align="center"
          gap={useBreakpointValue({ base: 2, md: 5 })}
        >
          <Button
            onClick={() => AddToCart(data?.data.result[0])}
            w={useBreakpointValue({ base: "100%", md: "50%" })}
            borderRadius="2rem"
            color="white"
            bg="#941C1C"
            _hover={{ filter: "brightness(105%)" }}
            transition="all ease-in-out 200ms"
          >
            <Icon as={FaShoppingCart} mr={3} />
            Book Now
          </Button>
          <Button
            w={useBreakpointValue({ base: "100%", md: "50%" })}
            borderRadius="2rem"
            color="#941C1C"
            border="1px solid #941C1C"
            bg="transparent"
            _hover={{ filter: "brightness(105%)" }}
            transition="all ease-in-out 200ms"
          >
            <Icon as={Download} mr={3} />
            <ChakraLink
              href="https://www.proqualab.com/wp-content/uploads/2016/10/ProQual-L3-NVQ-Diploma-in-Trowel-Occupations-Dec-2020.pdf"
              isExternal
            >
              Course Specifications
            </ChakraLink>
          </Button>
        </Flex>
        <VStack mt={6} textAlign="left" justify="start" align="start">
          <Text fontWeight="bold">About this NVQ assessment</Text>
          <Text>
            The aim of this qualification is to recognise the knowledge, skills
            and competence of individuals who specialise in bricklaying in the
            construction industry. The learner will need to demonstrate skills,
            knowledge and understanding in core subject areas, and will also
            need to demonstrate occupational competence in a specialist subject
            area.
          </Text>

          <Text fontWeight="bold">Entry Requirements</Text>
          <Text>
            You must be able to demonstrate that you can meet the skills,
            knowledge and experience requirements of the NVQ before enrolment.
          </Text>

          <Text fontWeight="bold">How long will my NVQ take to complete?</Text>
          <Text>
            An assessment plan will be agreed during your Initial
            assessment/Induction and a planned end date agreed. However, we
            would expect your qualification to be complete with 8 weeks
            depending on the learners time availability.
          </Text>

          <Text fontWeight="bold">Qualification Structure</Text>
          <Text>
            Candidates must complete all of the Mandatory units, plus a minimum
            of one Optional Unit.
          </Text>
          <Text>
            Mandatory units
            <br />
            - Pathway 1: A/503/2772 Confirming work activities and resources for
            an occupational work area in the workplace <br />
            - Pathway 2: M/503/2915 Developing and maintaining good occupational
            working relationships in the workplace <br />
            - Pathway 3: R/503/2924 Confirming the occupational method of work
            in the workplace <br />
            - Pathway 4: A/503/1170 Conforming to general health, safety and
            welfare in the workplace <br />- Pathway 5: A/503/9463 Erecting
            masonry structures in the workplace <br />
            - Pathway 6: Y/503/9471 Setting out to form masonry structures in
            the workplace
            <br />- Pathway 7: T/503/9476 Erecting masonry cladding in the
            workplace <br />- Pathway 8: A/618/3329 Erecting masonry to form
            architectural and decorative structures in the workplace
          </Text>

          <Text fontWeight="bold">Industry recognition & benefits</Text>
          <Text>
            The NVQ Level 3 Diploma in Trowel Occupations qualifies the holder
            to apply for the Construction Skills Certification Scheme (CSCS){" "}
            <ChakraLink
              as={RouterLink}
              to={"/detail-page-4"}
              style={{ color: "blue", fontWeight: "bold" }}
            >
              Gold CSCS Card
            </ChakraLink>
          </Text>
        </VStack>
      </VStack>
      <ContactForm />
    </Flex>
  );
}

export default CourseDetailLevelThree4Page;
