import {
  Heading,
  Text,
  VStack,
  Button,
  Icon,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
} from "@chakra-ui/react";
import { FaShoppingCart } from "react-icons/fa";
import { MdOutlineFileDownload as Download } from "react-icons/md";
import React from "react";
import { useQuery } from "react-query";
import { MdChevronRight } from "react-icons/md";
import ContactForm from "../nvqPages/course-details/contactForm";
import { useCart } from "../cart/addToCart";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

function SmstsFiveDays() {
  const { isPending, error, data } = useQuery(["product"], () => {
    return axios.get(`product/filter`, {
      params: {
        filter: "detailsPage",
        equal: "/smsts-online",
      },
    });
  });
  const { addToCart } = useCart();
  const navigate = useNavigate();
  const AddToCart = (data) => {
    const { _id, name, price } = data;
    addToCart(
      { _id, name, price, vat: data.productCategory.vat, quantity: 1 },
      0
    );
    navigate("/checkout");
  };
  return (
    <Flex
      direction={{ base: "column", md: "row" }}
      bg="white"
      p={2}
      justify={"space-between"}
      px={{ base: 4, md: "4rem" }}
      maxW={"150ch"}
      gap={2}
      mx="auto"
      position="relative"
      minHeight="100vh"
    >
      <VStack
        spacing={4}
        align="stretch"
        w={{ base: "100%", md: "55%" }}
        px={{ base: 0, md: 10 }}
        my={{ base: 4, md: "3rem" }}
      >
        <Breadcrumb
          mb={5}
          fontSize={"xs"}
          fontWeight={500}
          spacing="4px"
          separator={<MdChevronRight color="gray.500" />}
        >
          <BreadcrumbItem>
            <BreadcrumbLink href="/">Home</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink href="/citb-courses">CITB Courses</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink href="/smsts-online">SMSTS</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Heading as="h1" size="lg" color="#941C1C" mb={3}>
          SMSTS training takes place over a 5-day period and is run through a
          network of CITB accredited Training Providers.
        </Heading>

        <Text fontSize={"xl"} fontWeight={"600"}>
          £{data?.data.result[0].price} + VAT
        </Text>
        <Flex
          direction={{ base: "column", md: "row" }}
          justify={"space-between"}
          align={"center"}
          gap={2}
        >
          <Button
            w={{ base: "100%", md: "50%" }}
            borderRadius={"2rem"}
            color={"white"}
            bg={"#941C1C"}
            _hover={{ filter: "brightness(105%)" }}
            onClick={() => {
              AddToCart(data?.data.result[0]);
            }}
          >
            <Icon as={FaShoppingCart} mr={3} />
            Book Now
          </Button>
        </Flex>
        <VStack mt={6} textAlign={"left"} justify={"start"} align={"start"}>
          <Text>
            The SMSTS (Site Management Safety Training Scheme) training course
            is one of the courses on offer from the{" "}
            <Link
              to={
                "https://www.bookmycourse.co.uk/courses/smsts_and_site_safety_plus_284/"
              }
            >
               Site Safety Plus
            </Link>
             Scheme and is the most popular site management training course for
            site managers within the construction industry.
          </Text>
          <Text>
            SMSTS training takes place over a 5-day period and is run through a
            network of CITB accredited Training Providers. It has been designed
            for construction site managers, site agents, quantity surveyors,
            construction directors, project directors, and persons responsible
            for planning, organising, monitoring, controlling and administering
            a construction workforce.
          </Text>
          <Text>
            Also known informally as the ‘Site Managers Safety Training Scheme’,
            this course will assist managers and potential managers to develop
            their knowledge, awareness and understanding of legal, moral and
            social responsibilities in respect of health, safety and welfare.
          </Text>
          <Text>
            Successful candidates will receive the CITB SMSTS certificate, which
            is issued on completion. This is valid for 5 years.
          </Text>

          <Text fontWeight={"bold"}>
            If you have already passed the 5-day CITB SMSTS course and need to
            renew your CITB SMSTS certificate, you will be eligible for the
            2-Day
            <Link to="https://www.bookmycourse.co.uk/courses/citb_smsts_refresher_course_349/">
              SMSTS Refresher,
            </Link>
            providing your certificate is still in date and valid.
          </Text>

          <Text>
            The Refresher course is designed to bring health and safety
            knowledge up to date and includes an overview of the legislative
            changes relating to site management and their impact on site safety
            in the workplace.
          </Text>

          <Text fontWeight="bold">CITB Course Detail </Text>
          <Text>
            Site management training is an essential component of safety on
            construction sites; the SMSTS course will help managers to organise,
            monitor and measure health and safety performance on site, whilst
            maintaining site safety training, systems and safe methods of work.
            <Text>This CITB course includes the following elements:</Text>
            <br />
            - The Health & Safety at Work Act and other regulations relevant to
            the management of site safety within the Construction Industry
            <br />
            - Site set up, CDM (Construction Design Management Regulations),
            risk assessments and method statements
            <br />- Scaffolding, working at height, electricity, excavations,
            demolitions, and confined spaces
            <br />- Accidents and the associated legislative requirements
            (RIDDOR)
            <br />- The need for site management training, control measures and
            adequate communication to maintain a good health and safety culture
            within the workplace
          </Text>
          <Text>
            Full attendance is essential and candidates will be continually
            assessed during the course and will be required to complete
            assignments. There is a multiple choice assessment at the end of the
            course.
          </Text>
        </VStack>
      </VStack>
      <ContactForm />
    </Flex>
  );
}

export default SmstsFiveDays;
